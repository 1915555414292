import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Location = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <div className='flex justify-center rounded-lg mt-10 mb-10'>
        <div className='rounded-lg shadow-2xl w-11/12 md:w-full md:ml-16 md:mr-12 ' data-aos="fade-up">
          <iframe
          title='chitral green guest house'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3226.5648562486813!2d71.7765991!3d36.0309181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38c4af43c560dd3d%3A0x30bb18e69acf8496!2sSiwakth%20Huts!5e0!3m2!1sen!2s!4v1724092197387!5m2!1sen!2s"
            width="100%" height="350" style={{ border: '0' }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Location