import React, { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo/logo.png";
import { FaBars } from "react-icons/fa";

import { animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const data = [
    { text: "HOME", url: "/" },
    { text: "ROOMS", url: "/rooms" },
    { text: "DINING", url: "/dining" },
    { text: "REVIEWS", url: "/reviews" },
    { text: "FARM HOUSE", url: "/farm_house" },
    { text: "GARM CHASMA", url: "/garm_chasma" },
    { text: "ABOUT", url: "/about" },
    { text: "CONTACT", url: "/contact" },
    { text: "BOOK NOW", url: "/rooms" },
  ];

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLinkClick = (link) => {
    scroll.scrollToTop();
    handleNav();
  };
  const handleLogoLinkClick = (link) => {
    scroll.scrollToTop();
  };
  const screentop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={`urbanist sticky top-0  z-[1000] text-black bg-white h-[15vh] px-2 md:px-4`}>
      <div className={`h-[15vh] flex justify-between  items-center font-bold text-sm `}>
        <div >
          <Link to="/" >
            <img className="h-16" src={Logo} alt="Chitral Green Guest House" onClick={handleLogoLinkClick} />
          </Link>
        </div>
        <div className="lg:hidden hover:text-green-500 hover:drop-shadow-xl px-2">
          <button
            className="inline-flex items-center rounded-md hover:text-green-500 ease-in-out transition-all duration-200 text-lg font-medium "
            onClick={handleNav}
          >
            <FaBars
              size={22}
              className="text-[#454545] drop-shadow-md cursor-pointer hover:text-green-500 ease-in-out transition-all duration-200"
            />
          </button>
        </div>
        <div className="hidden lg:inline ">
          <ul className="flex justify-center items-center">
            {data.map((li, index) => (
              <li
                key={index}
                className="text-[14px] hover:text-green-500 drop-shadow-xl relative group px-4 py-2"
                onClick={screentop}
              >
                <Link to={li.url} className="flex cursor-pointer">

                  {li.text === "BOOK NOW" ? <>
                    <button className="bg-green-500 flex justify-center items-center hover:text-white transition-all duration-300 ease-in-out px-4 py-2">{li.text}!</button>
                  </> : li.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={
          nav
            ? "bg-white absolute right-0 top-0 w-80 z-1000 rounded-sm shadow-md overflow-y-auto text-black"
            : "fixed right-[-100%]"
        }
      >
        <div className="sticky h-full bg-white overflow-y-auto">
          <ul className="pt-4 px-6 bg-white pb-3">
            <div className="flex justify-end pr-2 pt-4">
              <TfiClose onClick={handleNav} className="rounded p-1 font-bold hover:bg-black/5" size={24} />
            </div>
            <div className="mt-6 h-full overflow-y-auto pb-6">
              {data.map((li, index) => (
                <>
                  <Link to={li.url}>
                    {li.text === "BOOK NOW" ? <>
                      <button className="bg-green-500 hover:text-white transition-all duration-300 ease-in-out px-4 py-2">{li.text}!</button>
                    </> :
                      <li
                        key={index}
                        className="cursor-pointer my-3 hover:text-green-500 py-2 pl-3 font-medium hover:rounded hover:bg-black/5 "
                        onClick={handleLinkClick}
                      >
                        {li.text}
                      </li>
                    }
                  </Link>
                </>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
