import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: "Where is Chitral Green Guest House located?",
      answer: "We are centrally located in Chitral...",
    },
    {
      question: "Do you offer room service?",
      answer: "Yes, we provide 24-hour room service to all guests.",
    },
    {
      question: "Are luxury rooms available?",
      answer: "Yes, we offer luxury rooms...",
    },
    {
      question: "Is Chitral Green Guest House affordable?",
      answer: "We offer a range of room options...",
    },
    {
      question: "How can I book a room?",
      answer: "You can book a room easily through our website...",
    },
  ];

  return (
    <section className="my-8 py-4 px-4 urbanist md:px-8">
      <h2
        className="pt-8 pb-3 font-bold drop-shadow-md"
        data-aos="fade-up"
        style={{ fontSize: "clamp(20px, 2vw, 32px)" }}
      >
        FAQ's
      </h2>
      <div className="mt-4">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="mb-4 border-b rounded-md"
            data-aos="fade-up"
          >
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full text-left p-4  bg-white focus:outline-none flex justify-between items-center"
            >
              <h3
                className="font-medium transition-all duration-200 ease-in-out"
                style={{ fontSize: "clamp(20px, 1.2vw, 24px)" }}
              >
                {faq.question}
              </h3>
              <span
                className="text-2xl font-bold"
              >
                {activeIndex === index ? "−" : "+"}
              </span>
            </button>
            {activeIndex === index && (
              <p
                className="p-4 bg-gray-100 transition-all duration-200 ease-in-out"
                data-aos="fade-in"
                style={{ fontSize: "clamp(18px, 1vw, 21px)" }}
              >
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default FAQ;
