import React from 'react'
import Content from './Content'
import DiningSection1 from './DiningSection1'
import Review from './Review'

function About() {
  return (
    <div>
      <DiningSection1 />
      <Review />
      <Content />
    </div>
  )
}

export default About