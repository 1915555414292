import React,{useEffect} from 'react';
import herovideo from "../../Assets/hero/farmhousehero.jpeg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="relative w-full h-[45vh] md:h-[85vh] overflow-hidden">
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center bg-black bg-opacity-50">
        <h1 className="text-4xl lg:text-6xl font-extrabold mb-4" data-aos="fade-up">
          Siwakth <span className="text-green-500">Huts</span>
        </h1>
        <div className='flex mt-2'  data-aos="fade-up-left">
          <div className='bg-green-600  h-[6px]  w-[28px]'></div>
          <div className='bg-gray-600 h-[6px]  w-[28px]'></div>
          <div className='bg-orange-600 h-[6px]  w-[28px]'></div>
        </div>
        <p className="text-[22px] mt-3" data-aos="fade-right">
          A Place Like Paradise
        </p>
      </div>
      {/* <div className="relative w-full h-[45vh] md:h-[85vh] overflow-hidden z-[-1]">
        <div className="absolute inset-0 w-full h-full">
        <iframe
            className="absolute top-0 left-0 w-full h-full object-cover"
            src="https://www.youtube.com/embed/QBCne8oT3vg?autoplay=1&loop=1&controls=0&mute=1&playlist=QBCne8oT3vg"
            title="Chitral Green Guest House"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}
 <div className="relative w-full h-[45vh] md:h-[85vh] overflow-hidden z-[-1]">
      <div className="absolute inset-0 w-full h-full">
        <img src={herovideo} alt="Home" className='w-full h-[45vh] md:h-[85vh] object-cover' />
      </div>
    </div>
    </div>
  );
};

export default Hero;

