import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { BiBed, BiBath, BiTv } from "react-icons/bi";
import Form2 from "./Form2";
import { MdOutlineBed } from "react-icons/md";
import { LuFlower2 } from "react-icons/lu";
import { GiMountainCave } from "react-icons/gi";
import { BiDish } from 'react-icons/bi';
import { animateScroll as scroll } from "react-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Content = ({ room }) => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  const renderIcons = (count, IconComponent) => {
    return Array.from({ length: count }, (_, i) => (
      <IconComponent key={i} className="text-[#293957] text-xl mr-1" />
    ));
  };
  const handleLinkClick = (link) => {
    scroll.scrollToTop();
  };
  return (
    <div className="px-6 mb-8 md:px-8 urbanist">
      <div className="my-10 md:flex-row flex-col space-y-7 md:space-y-0 flex justify-between border-b-2" data-aos="fade-up">
        <div >
          <Link to="/" onClick={handleLinkClick} rel="noreferrer">
            Home
          </Link>
          <span className="px-2">/</span>
          <Link
            to="/rooms"
            onClick={handleLinkClick}
            className="border-b-2 whitespace-nowrap hover:text-green-500 hover:border-black transition-all duration-300 ease-in-out"
          >
            Rooms
          </Link>
          <span className="px-2">/</span>
            {room.name}
        </div>
        <div className="flex pb-8">
          <button
            type="button"
            className="flex items-center transition-all duration-300 ease-in-out justify-center bg-[#edeff1] text-black hover:bg-green-600 hover:text-white px-4 py-2 border-2 rounded-[6px]"
          >
            <FaEnvelope />
            <h1 className="mx-2 font-bold">EMAIL</h1>
          </button>
          <button
            type="button"
            className="flex items-center justify-center transition-all duration-300 ease-in-out bg-green-600 text-white px-4 py-2 border-2 rounded-[6px] ml-4"
          >
            <FaWhatsapp />
            <h1 className="mx-2 font-bold text-white">WHATSAPP</h1>
          </button>
        </div>
      </div>
      <h1 className="font-bold text-xl md:text-3xl my-7" data-aos="fade-right">{room.name} - {room.price} PKR</h1>
      <div className="grid lg:grid-cols-2 gap-8">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6" data-aos="fade-right">
            {room.single_bed > 0 && (
              <div className="flex items-center" data-aos="fade-up">
                {renderIcons(parseInt(room.single_bed, 10), BiBed)}

                <span className="text-[#293957] font-bold ml-2">
                  {room.single_bed} <span>Single Bed</span>
                </span>
              </div>
            )}
            {room.double_bed > 0 && (
              <div className="flex items-center" data-aos="fade-up">
                {renderIcons(parseInt(room.double_bed, 10), MdOutlineBed)}
                <span className="text-[#293957] font-bold ml-2">
                  {room.double_bed}
                  <span> Double Bed</span>
                </span>
              </div>
            )}
            {room.area > 0 && (
              <>
                <div className="flex relative items-center" data-aos="fade-up">
                  <svg
                    class="bk-icon -streamline-room_size"
                    height="16"
                    width="16"
                    viewBox="0 0 24 24"
                    role="presentation"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M3.75 23.25V7.5a.75.75 0 0 0-1.5 0v15.75a.75.75 0 0 0 1.5 0zM.22 21.53l2.25 2.25a.75.75 0 0 0 1.06 0l2.25-2.25a.75.75 0 1 0-1.06-1.06l-2.25 2.25h1.06l-2.25-2.25a.75.75 0 0 0-1.06 1.06zM5.78 9.22L3.53 6.97a.75.75 0 0 0-1.06 0L.22 9.22a.75.75 0 1 0 1.06 1.06l2.25-2.25H2.47l2.25 2.25a.75.75 0 1 0 1.06-1.06zM7.5 3.75h15.75a.75.75 0 0 0 0-1.5H7.5a.75.75 0 0 0 0 1.5zM9.22.22L6.97 2.47a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06L8.03 2.47v1.06l2.25-2.25A.75.75 0 1 0 9.22.22zm12.31 5.56l2.25-2.25a.75.75 0 0 0 0-1.06L21.53.22a.75.75 0 1 0-1.06 1.06l2.25 2.25V2.47l-2.25 2.25a.75.75 0 0 0 1.06 1.06zM10.5 13.05v7.2a2.25 2.25 0 0 0 2.25 2.25h6A2.25 2.25 0 0 0 21 20.25v-7.2a.75.75 0 0 0-1.5 0v7.2a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-7.2a.75.75 0 0 0-1.5 0zm13.252 2.143l-6.497-5.85a2.25 2.25 0 0 0-3.01 0l-6.497 5.85a.75.75 0 0 0 1.004 1.114l6.497-5.85a.75.75 0 0 1 1.002 0l6.497 5.85a.75.75 0 0 0 1.004-1.114z"></path>
                  </svg>
                  <span className="text-[#293957] font-bold ml-2">
                    {room.area} m{" "}
                    <span className="bottom-3 absolute text-[11px]">2</span>{" "}
                    <span className="ml-2">Area</span>
                  </span>
                </div>
              </>
            )}
            {room.break_fast > 0 && (
              <>
                <div className="flex items-center">
                  <BiDish className="text-[#293957] text-xl " />

                  <span className="text-[#293957] font-bold ml-2">
                    Break Fast
                  </span>
                </div>
              </>
            )}
            {room.garden_view > 0 && (
              <div className="flex items-center" data-aos="fade-up">
                <LuFlower2 className="text-[#293957] text-xl " />

                <span className="text-[#293957] font-bold ml-2">
                  Garden View
                </span>
              </div>
            )}
            {room.mountain_view > 0 && (
              <>
                <div className="flex items-center" data-aos="fade-up">
                  <GiMountainCave className="text-[#293957] text-xl " />

                  <span className="text-[#293957] font-bold ml-2">
                    Mountain View
                  </span>
                </div>
              </>
            )}
            {room.air_conditionar > 0 && (
              <>
                <div className="flex items-center" data-aos="fade-up">
                  <svg
                    class="bk-icon -streamline-weather_snowflake"
                    height="16"
                    width="16"
                    viewBox="0 0 24 24"
                    role="presentation"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M11.25.75v7.5a.75.75 0 0 0 1.5 0V.75a.75.75 0 0 0-1.5 0zm4.031.914l-3.75 3h.938l-3.75-3a.75.75 0 1 0-.938 1.172l3.75 3a.75.75 0 0 0 .938 0l3.75-3a.75.75 0 1 0-.938-1.172zM1.883 7.024l6.495 3.75a.75.75 0 0 0 .75-1.299l-6.495-3.75a.75.75 0 1 0-.75 1.3zM4.69 3.99l.723 4.748.468-.812-4.473 1.748a.75.75 0 0 0 .546 1.398l4.473-1.748a.75.75 0 0 0 .468-.812l-.723-4.748a.75.75 0 1 0-1.482.226zM2.632 18.274l6.495-3.75a.75.75 0 1 0-.75-1.298l-6.495 3.75a.75.75 0 1 0 .75 1.299zm-1.224-3.948l4.473 1.748-.468-.812-.723 4.748a.75.75 0 0 0 1.482.226l.723-4.748a.75.75 0 0 0-.468-.812l-4.473-1.748a.75.75 0 0 0-.546 1.398zM12.75 23.25v-7.5a.75.75 0 0 0-1.5 0v7.5a.75.75 0 0 0 1.5 0zm-4.031-.914l3.75-3h-.938l3.75 3a.75.75 0 0 0 .937-1.172l-3.75-3a.75.75 0 0 0-.937 0l-3.75 3a.75.75 0 0 0 .938 1.172zm13.399-5.36l-6.495-3.75a.75.75 0 0 0-.75 1.298l6.495 3.75a.75.75 0 0 0 .75-1.299zm-2.807 3.034l-.724-4.748-.468.812 4.473-1.748a.75.75 0 0 0-.546-1.398l-4.473 1.748a.75.75 0 0 0-.468.812l.723 4.748a.75.75 0 0 0 1.483-.226zm2.057-14.285l-6.495 3.75a.75.75 0 0 0 .75 1.3l6.495-3.75a.75.75 0 0 0-.75-1.3zm1.224 3.95l-4.473-1.749.468.812.724-4.748a.75.75 0 0 0-1.483-.226l-.723 4.748a.75.75 0 0 0 .468.812l4.473 1.748a.75.75 0 0 0 .546-1.398zM11.625 7.6L8.377 9.475a.75.75 0 0 0-.375.65v3.75a.75.75 0 0 0 .375.65l3.248 1.874a.75.75 0 0 0 .75 0l3.248-1.875a.75.75 0 0 0 .375-.649v-3.75a.75.75 0 0 0-.375-.65L12.375 7.6a.75.75 0 0 0-.75 0zm.75 1.3h-.75l3.248 1.874-.375-.649v3.75l.375-.65-3.248 1.876h.75l-3.248-1.876.375.65v-3.75l-.375.65L12.375 8.9z"></path>
                  </svg>

                  <span className="text-[#293957] font-bold ml-2">
                    Air Conditionaring
                  </span>
                </div>
              </>
            )}

            {room.private_bathroom > 0 && (
              <>
                <div className="flex items-center" data-aos="fade-up">
                  <BiBath className="text-[#293957] text-xl " />

                  <span className="text-[#293957] font-bold ml-2">
                    Private Bathroom
                  </span>
                </div>
              </>
            )}

            {room.flat_screen_tv > 0 && (
              <>
                <div className="flex items-center" data-aos="fade-up">
                  <BiTv className="text-[#293957] text-xl " />
                  <span className="text-[#293957] font-bold ml-2">
                    Flat-Screen TV
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <Form2 room_id = {room.id} room_name = {room.name} />
      </div>
    </div>
  );
};

export default Content;