import React from 'react'

const Location = () => {
  return (
    <div>
      <div className='flex justify-center rounded-lg mt-10 mb-10'>
        <div className='rounded-lg shadow-2xl w-11/12 md:w-full md:ml-16 md:mr-12 '>
          <iframe
          title='chitral green guest house'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.875407151739!2d71.77785677464601!3d35.85205852057302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38db53038db262e7%3A0x4a79a72ed0e640cf!2sChitral%20Green%20Guest%20House!5e0!3m2!1sen!2s!4v1724090761048!5m2!1sen!2s"
            width="100%" height="350" style={{ border: '0' }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Location