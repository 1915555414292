import React,{useEffect} from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import AOS from 'aos';
import 'aos/dist/aos.css';

 
const CustomButtonGroupAsArrows = ({ next, previous }) => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
  <div className="flex absolute bottom-0 px-4 md:px-0  md:mx-auto w-full justify-between md:justify-center items-center 4 lg:mt-6" >
      <button
        onClick={previous}
        className="mr-4   w-[50px] h-[50px] flex justify-center items-center rounded-[12px] hover:text-black cursor-pointer text-green-500 text-[40px] font-bold transition-all duration-300 ease-in-out border-2 border-[#d7d7d7] hover:border-green-500"
     data-aos="fade-right"
     >
        <FaArrowLeftLong size={20}/>
      </button>
      <button
        onClick={next}
        className="ml-1  w-[50px] h-[50px] flex justify-center items-center rounded-[12px] hover:text-black cursor-pointer text-green-500 text-[40px] font-bold transition-all duration-300 ease-in-out border-2 border-[#d7d7d7] hover:border-green-500"
     data-aos="fade-left"
     >
        <FaArrowRightLong size={20}/>
      </button>
    </div>
  );
};

export { CustomButtonGroupAsArrows };