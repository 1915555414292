import React, { useState } from 'react';
import axios from 'axios';
// import Alert from '../../../MainComponents/Alert/Alert'
import { FaEnvelope,  FaUser,  FaPhoneAlt } from "react-icons/fa";

const Form2 = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      setMessage('Booking order...');
      setError('');
      const url = 'http://localhost:8080/api/booknow';
      const { data: res } = await axios.post(url, data);
      console.log(res.message);
      console.log(message)
      setMessage('Order email has been sent successfully.');
      setData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="flex justify-center bg-white p-4 sm:p-6 lg:p-10 rounded-lg shadow-2xl">
        <form className="flex justify-center w-full" onSubmit={handlesubmit}>
          <div className="flex flex-col justify-center mb-4 w-11/12">
            <h2 className="text-4xl font-bold mb-4">CONTACT US</h2>
            <div className="">
              <div className="flex flex-col">
              <div className="relative flex items-center h-full">
                  <FaUser className="text-gray-500 text-lg top-[18px] absolute left-4" />
                  <input className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md mb-4 bg-gray-100 placeholder:text-gray-500 outline-none" type="text" id="name" name="name" value={data.name} onChange={handleChange} required placeholder="Full Name" />
                </div>
                <div className="relative flex items-center ">
                  <FaEnvelope className="text-gray-500 text-lg top-[18px] absolute left-4" />
                  <input className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md mb-4 bg-gray-100 placeholder:text-gray-500 outline-none" type="email" id="email" name="email" value={data.email} onChange={handleChange} required placeholder="Email Address*" />
                </div>
                <div className="relative flex items-center">
                <FaPhoneAlt className="text-gray-500 text-lg absolute left-4 top-[18px] " />
                  <input className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md mb-4 bg-gray-100 placeholder:text-gray-500 outline-none" type="number" id="phone" name="phone" value={data.phone} onChange={handleChange} required placeholder="Phone" />
                </div>
              </div>
              <textarea className="w-full px-6 py-4 border focus:border-green-500 rounded-md mb-4 h-40 bg-gray-100 placeholder:text-gray-500 outline-none" id="message" name="message" value={data.message} onChange={handleChange} required placeholder="Write your message here..." />
              {error && <div>{error}</div>}
              <button className="bg-green-500 transition-all duration-300 ease-in-out hover:bg-green-600 text-white  text-lg hover:outline font-bold p-4 rounded-lg w-full" type="submit"> CONTACT US </button>
            </div>
          </div>
        </form>      </div>
      {/* {message && <Alert message={message} />} */}
    </>
  );
};

export default Form2;
