import React from 'react'
import RoomSection1 from './RoomSection1'
import Rooms from '../Home/Rooms'

function Room() {
  return (
    <div>
      <RoomSection1 />
      <Rooms  />
    </div>
  )
}

export default Room