import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import hero1 from "../../Assets/hero/review.jpg";
function ReviewsSection1() {
  useEffect(() => {
    AOS.init();
  }, []);
  const backgroundStyle = {
    backgroundImage: `url(${hero1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };
  return (
    <>
      <div
        className=" py-36 text-center flex flex-col justify-center items-center px-6 md:px-8 urbanist h-[90vh]"
        style={backgroundStyle}
      >
        <h1
          className="text-white text-[35px] md:text-[45px] font-bold fontjost mb-4"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Reviews
        </h1>
        <p
          className="text-white md:text-base fontjost font-bold"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          <Link to="/">
            <span className="hover:underline hover:text-white transition-all duration-300 ease-in-out">
              {" "}
              Home{" "}
            </span>
          </Link>{" "}
          / Reviews
        </p>
      </div>
    </>
  );
}

export default ReviewsSection1;
