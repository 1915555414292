import React,{useEffect} from 'react';
import img1 from '../../Assets/food_review/1.jpg'
import img2 from '../../Assets/food_review/2.jpg'
import { FlagIcon } from "react-flag-kit";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Review = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="flex justify-center flex-col items-center min-h-screen pb-16 bg-gray-100">
      <h2 className='urbanist text-center font-bold  text-3xl py-16'  data-aos="fade-up">Review</h2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 p-4'>
        <div className="max-w-xl bg-white rounded-lg shadow-lg overflow-hidden"  data-aos="fade-right">
          <img
            className="w-full h-72 object-cover"
            src={img1}
            alt="Profile"
          />
          <div className="p-6">

            <div className="flex items-center mb-3">
              <h2 className="text-2xl font-semibold text-gray-800 text-center mb-2">Hajkova From Czech Republic</h2>
              <FlagIcon code="CZ" size={24} className="ml-2" />
            </div>
            <p className="text-gray-600">
              The food here was an absolute delight! The traditional Chitrali dishes had a modern twist that elevated the flavors to a whole new level. Each meal was a journey through the rich culinary history of the region. The desserts were heavenly.
            </p>
          </div>
        </div>
        <div className="max-w-xl bg-white rounded-lg shadow-lg overflow-hidden"  data-aos="fade-left">
          <img
            className="w-full h-72 object-cover"
            src={img2}
            alt="Profile"
          />
          <div className="p-6">

            <div className="flex items-center mb-3">
              <h2 className="text-2xl font-semibold text-gray-800 text-center mb-2">Harrsion and Anna Elizabeth From Norway</h2>
              <FlagIcon code="NO" size={24} className="ml-2" />
            </div>
            <p className="text-gray-600">
              As food enthusiasts, we were thoroughly impressed with the quality and variety of the meals offered. The chitrali dishes were incredibly fresh and flavorful, reminding us of home in Norway. The attention to detail in the presentation and the use of local ingredients made our dining experience unforgettable. Highly recommend this place to fellow travelers!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
