import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function WhyUs() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="my-12 urbanist py-6 px-4 md:px-8">
      <div className="mx-auto">
        <div className="mt-8 lg:mt-0" data-aos="fade-right">
          <h2
            className="font-bold drop-shadow-md text-center"
            style={{ fontSize: "clamp(20px, 2vw, 32px)" }}
          >
            Why Choose Us
          </h2>
          <p
            className="mt-4"
            style={{ fontSize: "clamp(16px, 1.5vw, 23px)" }}
          >
            Choosing Chitral Green Guest House means choosing comfort, quality,
            and hospitality. As a private guest house in Chitral, we provide an
            intimate, personalized stay. Whether you need a cheap guest house in
            Chitral or a more luxurious option, we focus on giving you the best
            experience.
          </p>
          <p
            className="mt-4"
            style={{ fontSize: "clamp(16px, 1.8vw, 20px)" }}
          >
            What makes us the best guest house in Chitral is our dedication to
            service. From the moment you arrive, our staff is here to meet your
            needs. Many guests return to us because they trust us to make their
            stay in Chitral enjoyable and worry-free.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
