import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import img1 from '../../Assets/garm chasma/1.jpg';
import img2 from '../../Assets/garm chasma/2.jpg';
import img3 from '../../Assets/garm chasma/3.jpg';
import img4 from '../../Assets/garm chasma/4.jpg';
import img5 from '../../Assets/garm chasma/5.jpg';
import img6 from '../../Assets/garm chasma/6.jpg';
import img7 from '../../Assets/garm chasma/7.jpg';
import img8 from '../../Assets/garm chasma/8.jpg';
import img9 from '../../Assets/garm chasma/9.jpg';
import img10 from '../../Assets/garm chasma/10.jpg';
import img11 from '../../Assets/garm chasma/11.jpg';
import img12 from '../../Assets/garm chasma/12.jpg';
import img13 from '../../Assets/garm chasma/13.jpg';
import img14 from '../../Assets/garm chasma/14.jpg';
import img15 from '../../Assets/garm chasma/15.jpg'

function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15];
  return (
    <>
      <div className="px-4 md:px-8 mt-16 mb-10">
        <p className="mx-[19%] urbanist md:mx-0 md:flex items-center justify-center text-2xl md:text-3xl font-bold text-gray-800 text-center" data-aos="fade-up">
          Knights In Droshp
        </p>
        <div className="my-8 text-black/80 leading-8  urbanist text-[1.2rem]" data-aos="fade-up">
          Knights In Droshp is located in Garam Chasma Valley. Garam Chasma is a renowned tourist destination, famous for its hot springs and trout fish. The area offers world-class fishing in the waters of the valley. The Lotkoh River (Garam Chashma River), flowing from the high peaks of the Hindu Kush, is home to brown trout.
          The region also boasts naturally occurring springs, pleasant weather, and snow-capped peaks. Recently, the Garam Chasma spring has gained recognition for its ability to alleviate bone pain. The spring water emerges from underground sulfur deposits, causing its temperature to rise above boiling point. For this reason, it is also known as Sulphur Spring. It is the warmest spring in the westward extension of the Himalayan Geothermal Belt, emerging from leucogranites of the Hindu Kush Range.
          Knights In Droshp features its own Trout Fish Farm, where tourists can purchase the famous trout fish from the Lotkoh River and enjoy a delightful meal.
        </div>
        <div className="mt-6 urbanist">
          <h2 className="text-[1.5rem] font-semibold text-gray-900" data-aos="fade-right">Contact Information</h2>
          <div className="flex flex-col sm:flex-row sm:items-center mt-3 text-gray-700 text-[1.2rem]" data-aos="fade-right">
            <span className="font-bold text-gray-800 mr-2" >Phone:</span>
            <span >0346-0980320</span>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center mt-2 text-gray-700 text-[1.2rem]" data-aos="fade-right">
            <span className="font-bold text-gray-800 mr-2" >Manager:</span>
            <span >Muhammad Rahim - Knights In Droshp</span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4 mt-10">
          {images.slice(0, 15).map((image, index) => (
            <div key={index} data-aos="fade-up" className="w-full">
              <img src={image} alt={`Gallery item ${index + 1}`} className="w-full h-full object-cover rounded-lg shadow-md" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Content;
