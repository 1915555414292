import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import hero1 from '../../Assets/hero/chashma.jpeg'
function GarmChasmaSection1() {
  useEffect(() => {
    AOS.init();
  }, [])
  const backgroundStyle = {
    backgroundImage: `url(${hero1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };
  return (

    <>
      <div className=" flex items-center justify-center h-screen text-center" style={backgroundStyle}>
        <div>
          <h1 className="text-white text-[35px] md:text-[45px] font-bold fontjost mb-4" data-aos="fade-up" data-aos-duration="500">
            Garm Chasma
          </h1>
          <p className="text-white md:text-base fontjost" data-aos="fade-up" data-aos-duration="700">
            <Link to='/'><span className='hover:underline hover:text-white transition-all duration-300 ease-in-out'> Home </span></Link> / Garm Chasma
          </p>
        </div>
      </div>
    </>
  )
}

export default GarmChasmaSection1