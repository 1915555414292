import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Section1() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <section className="p-6 mt-12 urbanist bg-white">
        <h2
          className="font-bold drop-shadow-md"
          data-aos="fade-right"
          style={{ fontSize: "clamp(18px, 2vw, 32px)" }}
        >
          Best Rooms and Facilities
        </h2>
        <p
          className="mt-4"
          data-aos="fade-up"
          style={{ fontSize: "clamp(16px, 1.5vw, 24px)" }}
        >
          At Chitral Green Guest House, our rooms are designed for both comfort
          and luxury. Each room is clean, spacious, and includes modern
          amenities. We offer single, double, and family rooms with beautiful
          views of Chitral.
        </p>
        <ul
          className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2 2xl:grid-cols-3 justify-center items-center"
          data-aos="fade-up"
        >
          <li
            className="border p-4 bg-gray-50 rounded-lg shadow-md hover:shadow-lg"
            data-aos="fade-up"
          >
            <h3
              className="font-bold"
              style={{ fontSize: "clamp(16px, 1.65vw, 28px)" }}
            >
              Single Room
            </h3>
            <p style={{ fontSize: "clamp(14px, 1.2vw, 20px)" }}>
              Comfortable and ideal for solo travelers.
            </p>
          </li>
          <li
            className="border p-4 bg-gray-50 rounded-lg shadow-md hover:shadow-lg"
            data-aos="fade-up"
          >
            <h3
              className="font-bold"
              style={{ fontSize: "clamp(16px, 1.65vw, 28px)" }}
            >
              Double Room
            </h3>
            <p style={{ fontSize: "clamp(14px, 1.2vw, 20px)" }}>
              Perfect for couples with a cozy ambiance.
            </p>
          </li>
          <li
            className="border p-4 bg-gray-50 rounded-lg shadow-md hover:shadow-lg"
            data-aos="fade-up"
          >
            <h3
              className="font-bold"
              style={{ fontSize: "clamp(16px, 1.65vw, 28px)" }}
            >
              Family Room
            </h3>
            <p style={{ fontSize: "clamp(14px, 1.2vw, 20px)" }}>
              Spacious rooms designed for families with children.
            </p>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Section1;
