import React,{useEffect} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomButtonGroupAsArrows } from './CustomArrows';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Section1 = ({ room }) => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  const slider = room.room_files.map(file => `https://api.chitralgreenguesthouse.com/uploads/${file}`);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <div className='relative' data-aos="fade-up">
      <Carousel
        swipeable={true}
        responsive={responsive}
        autoPlay
        infinite={true}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows />}
      >
        {slider.map((img, i) => (
          <div
            key={i}
            className='h-[40vh] sm:h-[45vh] object-contain md:h-[60vh] mb-16'
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          >
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Section1;
