import React from 'react'

const Location = () => {
  return (
    <div>
      <div className='flex justify-center rounded-lg mt-10 mb-10'>
        <div className='rounded-lg shadow-2xl w-11/12 md:w-full md:ml-16 md:mr-12 '>
          <iframe
          title='chitral green guest house'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3227.979463072657!2d71.57703610000002!3d35.9963684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38c4b7724468aa5b%3A0x9b6a1d6708a9c563!2sKnights%20Inn%20Droshp!5e0!3m2!1sen!2s!4v1724091657218!5m2!1sen!2s"
            width="100%" height="350" style={{ border: '0' }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Location