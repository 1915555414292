"use client"
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRooms } from '../../redux/actions/roomAction';
import { useLocation } from 'react-router-dom';
import Content from './Content';
import Section1 from './Section1';

function RoomDescription() {
  const location = useLocation(); 
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  console.log(id);
  
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(fetchRooms());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 bg-blue-500 rounded-full animate-ping"></div>
          <div className="w-4 h-4 bg-blue-500 rounded-full animate-ping delay-200"></div>
          <div className="w-4 h-4 bg-blue-500 rounded-full animate-ping delay-400"></div>
        </div>
        <p className="text-gray-500 text-lg ml-4">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  const room = data.find(room => room.id === parseInt(id));

  if (!room) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-500 text-lg">Room not found</p>
      </div>
    );
  }

  return (
    <>    
      <Section1 room={room} />
      <Content room={room} />
    </>
  );
}

export default RoomDescription;
