import React from 'react'
import ContactSection2 from './ContactSection2'
import Location from './Location'
import ContactSection1 from './ContactSection1'

function Contact() {
  return (
    <div>
      <ContactSection1 />
      <ContactSection2/>
      <Location />
    </div>
  )
}

export default Contact