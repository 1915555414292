import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { FiPhone } from "react-icons/fi";
import { FaRegEnvelope } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Form from './Form'
const ContactSection2 = () => {
    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: 'ease',
        });
    }, []);

    return (
        <>
            <div className='flex justify-center px-6 md:px-8 urbanist'>
                <div className="flex flex-col md:flex-row justify-center mx-1  my-4 md:my-24 w-[1000px] " data-aos="fade-up">
                    <div className="w-full p-0 md:p-10   text-black mb-8 md:mb-0">
                        <h2 className="text-lg font-semibold mb-4 mt-10 flex items-center justify-start"><FiPhone className='text-green-500 text-[1.5rem]' /> <p className='pl-2'>Call Us</p></h2>
                        <p>+92 300 5821648</p>
                        <h2 className="text-lg font-semibold mb-4 mt-4 flex items-center justify-start"><HiOutlineLocationMarker className='text-green-500 text-[1.5rem] font-bold' /> <p className='pl-2'>Location</p></h2>
                        <p>Main Chital City Near Chitral Officer Mess</p>

                        <h2 className="text-lg font-semibold mb-4 mt-4 flex items-center justify-start"><FaRegEnvelope className='text-green-500 text-[1.5rem]' /> <p className='pl-2'>Email</p></h2>
                        <p>chitralgreenguesthouse11@gmail.com</p>
                    </div>
                    <div className="w-full mt-4  md:mt-0 " data-aos="fade-up">
                        <Form />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactSection2;