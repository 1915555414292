import React from 'react'
import Hero from './Hero'
import Content from './Content'
import Location from './Location'

function Farm_House() {
  return (
    <div>
      <Hero/>
      <Content />
      <Location />
    </div>
  )
}

export default Farm_House