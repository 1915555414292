import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import hero from '../../Assets/hero/home.jpeg'
const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="relative w-full h-[45vh] md:h-[85vh] overflow-hidden">
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center bg-black bg-opacity-50">
        <h1 className="text-4xl lg:text-6xl font-extrabold mb-4" data-aos="fade-up">
          Chitral <span className="text-green-500">Green</span> Guest House
        </h1>
        <div className="flex mt-2" data-aos="fade-up-left">
          <div className="bg-green-600 h-[6px] w-[28px]"></div>
          <div className="bg-gray-600 h-[6px] w-[28px]"></div>
          <div className="bg-orange-600 h-[6px] w-[28px]"></div>
        </div>
        <p className="text-[22px] mt-3" data-aos="fade-right">
          A Place Like Home
        </p>
        <Link to='/rooms'>
          <div className="bg-transparent mt-3 border-2 cursor-pointer text-white border-white flex justify-center items-center hover:text-white transition-all duration-300 ease-in-out px-4 py-2"
               data-aos="fade-up">BOOK NOW</div>
        </Link>
      </div>
   
 <div className="relative w-full h-[45vh] md:h-[85vh] overflow-hidden z-[-1]">
      <div className="absolute inset-0 w-full h-full">
        <img src={hero} alt="Home" className='w-full h-[45vh] md:h-[85vh] object-cover' />
      </div>
    </div>
       
    </div>
  );
};

export default Hero;
