import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import img1 from '../../Assets/farmhouse/1.jpg';
import img2 from '../../Assets/farmhouse/2.jpg';
import img3 from '../../Assets/farmhouse/3.jpg';
import img4 from '../../Assets/farmhouse/4.jpg';
import img5 from '../../Assets/farmhouse/5.jpg';
import img6 from '../../Assets/farmhouse/6.jpg';
import img7 from '../../Assets/farmhouse/7.jpg';
import img8 from '../../Assets/farmhouse/8.jpg';
import img9 from '../../Assets/farmhouse/9.jpg';
import img10 from '../../Assets/farmhouse/10.jpg';
import img11 from '../../Assets/farmhouse/11.jpg';
import img12 from '../../Assets/farmhouse/12.jpg';
import img13 from '../../Assets/farmhouse/13.jpg';
import img14 from '../../Assets/farmhouse/14.jpg';
import img15 from '../../Assets/farmhouse/15.jpg'
import img16 from '../../Assets/farmhouse/16.jpg'
import img17 from '../../Assets/farmhouse/17.jpg'
import img18 from '../../Assets/farmhouse/18.jpg'
import VideoEmbed from "./VideoEmbed";
function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
   
    });
  }, []);

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18];

  return (
    <>
      <div className="px-4 md:px-8 mt-16 mb-10">
        <p className="mx-[19%] md:mx-0 md:flex items-center justify-center text-2xl md:text-3xl font-bold text-gray-800  text-center urbanist" data-aos="fade-up">
          Siwakth Huts Farm House
        </p>
        <div className="my-8 urbanist text-black/80 leading-8 text-[1.2rem]" data-aos="fade-up">
          Siwakth Huts is a farmhouse stay project by Chitral Green Guest House, designed for both national and international guests who wish to experience nature up close. Located in Siwakth Village of Chitral Valley, it is approximately a 1.5-hour drive from Chitral City and lies along the route to Garam Chasma Valley. Spanning an area of around 12 acres, Siwakth Huts offers a unique farmhouse experience amidst the natural beauty and local culture of Chitral Valley.
          Siwakth Huts is ideal for families and group tours. It features its own kitchen, allowing guests to prepare their own meals, or they can enjoy traditional Chitrali and Pakistani dishes prepared by our skilled cooking staff. The property is abundant with fruit trees and greenery, providing a perfect setting for nature lovers and camping enthusiasts.
          Experience the tranquility of nature and the charm of the northern area at Siwakth Huts. For pre-booking, kindly contact the Chitral Green Guest House team. We look forward to welcoming you!
          It truly feels like heaven.
          See you soon,
          <br />
          Regards,
          <br />
          Chitral Green Guest House
        </div>
        <VideoEmbed data-aos="fade-up"/>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4 mt-10">
          {images.slice(0, 18).map((image, index) => (
            <div key={index} data-aos="fade-up" className="w-full">
              <img src={image} alt={`Gallery item ${index + 1}`} className="w-full h-full object-cover rounded-lg shadow-md" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Content;
