import React, { useEffect } from "react";
import Hero from "./Hero";
import Rooms from "./Rooms";
import AOS from "aos";
import FAQ from "./FAQ";
import "aos/dist/aos.css";
import Section1 from "./Section1";
import WhyUs from "./WhyUs";
import About from "./About";
import DrivingDirections from "./DrivingDirections";
import BestPlaces from "./BestPlaces";
import VideoEmbed from "./VideoEmbed";
function Home() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div>
      <Hero />
      <About />
      <Section1 />
      <Rooms />
      <WhyUs />
      <FAQ />
      <BestPlaces />
      <VideoEmbed />
      <DrivingDirections />
    </div>
  );
}

export default Home;
