import React from 'react'
import GarmChasmaSection1 from './GarmChasmaSection1'
import Content from './Content'
import Location from './Location'
function Garm_Chasma() {
  return (
    <div>
      <GarmChasmaSection1 />
      <Content />
      <Location />
    </div>
  )
}

export default Garm_Chasma