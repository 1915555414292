import React, { useEffect } from "react";
import { FlagIcon } from "react-flag-kit";
import img1 from "../../Assets/reviews/1.jpg";
import img2 from "../../Assets/reviews/2.jpg";
import img3 from "../../Assets/reviews/3.jpg";
import img4 from "../../Assets/reviews/4.jpg";
import img5 from "../../Assets/reviews/5.jpg";
import img6 from "../../Assets/reviews/6.jpg";
import img7 from "../../Assets/reviews/7.jpg";
import img8 from "../../Assets/reviews/8.jpg";
import img9 from "../../Assets/reviews/9.jpg";
import img10 from "../../Assets/reviews/10.jpg";
import img11 from "../../Assets/reviews/11.jpg";
import img12 from "../../Assets/reviews/12.jpg";
import img14 from "../../Assets/reviews/14.jpg"
import img15 from "../../Assets/reviews/15.jpg"
import img16 from "../../Assets/reviews/16.jpg"
import img17 from "../../Assets/reviews/17.jpg"

import AOS from 'aos';
import 'aos/dist/aos.css';
const ReviewsContent = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const guests = [
    {
      img: img1,
      code: "NL",
      name: "Guest Milian from Netherlands",
      description:
        "Milian had a wonderful stay at our guest house. He mentioned that the hospitality was exceptional, and the local experience was truly unique.",
    },
    {
      img: img2,
      code: "SK",
      name: "Guest Kue Joe from South Korea",
      description:
        "Kue Joe appreciated the cozy atmosphere of our guest house. He particularly enjoyed the friendly staff and the excellent service provided.",
    },
    {
      img: img3,
      code: "DE",
      name: "Guest Susanne from Germany, International Cyclist",
      description:
        "Susanne loved the peaceful environment and the comfortable accommodations. She said it was the perfect place to relax after a long cycling trip.",
    },
    {
      img: img14,
      code: "CR",
      name: "Guest Couple from Czech Republic",
      description:
        "The couple enjoyed the delicious homemade meals, especially the traditional Chitrali breakfast options. They mentioned that the friendly hosts made them feel right at home."
    },
    {
      img: img4,
      code: "ES",
      name: "Group of International tourists from Spain",
      description:
        "The group tourists from Spain was impressed by the beautiful surroundings and the warm welcome they received. They mentioned that the guest house felt like a home away from home.",
    },
    {
      img: img5,
      code: "MY",
      name: "Guest Mohd from Malaysia, Malaysian Ambassador",
      description:
        "Mohd praised the guest house for its exceptional service and tranquil setting. He said it was an ideal retreat for anyone seeking peace and quiet.",
    },
    {
      img: img17,
      code: "US",
      name: "Guest Couple From United States",
      description:
        "This couple enjoyed the cozy atmosphere and appreciated the peaceful surroundings. They remarked on the excellent service.",
    },
    {
      img: img6,
      code: "CA",
      name: "Guest Sandra Teresa from Canada",
      description:
        "Sandra was delighted by the charming decor and the attentive staff. She mentioned that the guest house exceeded her expectations in every way.",
    },
    
    {
      img: img15,
      code: "NO",
      name: "Guest Couple from Norway",
      description:
        "They praised the excellent hospitality and the variety of food offered. The couple particularly enjoyed the fresh pastries and locally sourced ingredients, noting that every meal felt like a special treat."
    },
    {
      img: img7,
      code: "NZ",
      name: "Guest David from New Zealand",
      description:
        "David found the guest house to be a hidden gem. He highlighted the excellent amenities and the serene atmosphere as his favorite aspects of the stay.",
    },
    {
      img: img16,
      code: "ES",
      name: "Garcia and Alfonso from Spain",
      description:
        "Garcia and Alfonso appreciated the warm hospitality and the well-maintained facilities at the guest house. They particularly enjoyed the beautiful surroundings and the comfortable rooms, making their stay a truly memorable experience",
    },
    {
      img: img8,
      code: "FR",
      name: "Guest Couple from France",
      description:
        "The couple from France enjoyed their romantic getaway at our guest house. They loved the privacy and the personalized service provided during their stay.",
    },
    {
      img: img9,
      code: "GB",
      name: "Guest Callum James from Britain",
      description:
        "Callum described the guest house as a perfect blend of comfort and luxury. He appreciated the attention to detail and the friendly staff who made his stay memorable.",
    },
    {
      img: img10,
      code: "IT",
      name: "Guest Capitano Luca from Italy",
      description:
        "Capitano Luca enjoyed the authentic experience offered by the guest house. He mentioned that the location and the warm hospitality made his stay truly special.",
    },
    {
      img: img11,
      code: "PK",
      name: "Chitral DPO Iftikhar Shah",
      description:
        "DPO Iftikhar Shah was impressed by the exceptional service and the stunning views from the guest house. He said it was an unforgettable experience.",
    },
    {
      img: img12,
      code: "PK",
      name: "Punjab Football Federation Guests",
      description:
        "The members of the Punjab Football Federation appreciated the spacious accommodations and the excellent facilities provided. They mentioned that it was an ideal place for team bonding.",
    }
  ];


  return (
    <div className="urbanist grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-12 mb-20 gap-8  px-4 md:px-8">
      {guests.map((item, index) => (
        <div
          key={index}
          className=" border border-gray-200 rounded-lg shadow-lg "
          data-aos="fade-up"
        >
          <img
            src={item.img}
            alt={item.name}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <div className="flex items-center mb-3">
              <h2 className="text-xl font-bold text-gray-800 mr-2">
                {item.name}
              </h2>
              <FlagIcon code={item.code} size={24} className="ml-2" />
            </div>
            <p className="text-gray-600">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewsContent;
