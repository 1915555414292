import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import img1 from '../../Assets/dining/1.jpg';
import img2 from '../../Assets/dining/2.jpg';
import img3 from '../../Assets/dining/3.jpg';
import img4 from '../../Assets/dining/4.jpg';
import img5 from '../../Assets/dining/5.jpg';
import img6 from '../../Assets/dining/6.jpg';
import { BiDish } from 'react-icons/bi';

function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
   
    });
  }, []);

  const images = [img1, img2, img3, img4, img5, img6];

  return (
    <>
      <div>
        <p className="mx-[19%] md:mx-0 md:flex items-center justify-center text-2xl md:text-3xl font-bold text-gray-800 my-4 text-center cavet"  data-aos="fade-up">
          Chitral Green Guest House Food Items <BiDish className="ml-2 text-gray-700 text-4xl md:text-4xl text-center w-full md:w-auto" />
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {images.slice(0, 3).map((image, index) => (
            <div key={index} data-aos="fade-up">
              <img src={image} alt={`Gallery item ${index + 1}`} className="w-full h-full object-cover rounded-lg shadow-md" />
            </div>
          ))}
          {images.slice(3).map((image, index) => (
            <div key={index} data-aos="fade-up">
              <img src={image} alt={`Gallery item ${index + 4}`} className="w-full h-full object-cover rounded-lg shadow-md" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Content;
