import axios from 'axios';

// Action Types
export const FETCH_ROOMS_REQUEST = 'FETCH_ROOMS_REQUEST';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE';

// Fetch Rooms Actions
const fetchRoomsRequest = () => ({ type: FETCH_ROOMS_REQUEST });
const fetchRoomsSuccess = (rooms) => ({ type: FETCH_ROOMS_SUCCESS, payload: rooms });
const fetchRoomsFailure = (error) => ({ type: FETCH_ROOMS_FAILURE, payload: error });

export const fetchRooms = () => {
  return async (dispatch) => {
    dispatch(fetchRoomsRequest());
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODE_API_ENDPOINT}api/get_all_rooms`);
      dispatch(fetchRoomsSuccess(response.data.rooms));
    } catch (error) {
      dispatch(fetchRoomsFailure(error.message));
    }
  };
};
