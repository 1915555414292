import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const VideoEmbed = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const videoIds = [
    { id: "WBZcEGRUahE", playlist: "WBZcEGRUahE" },
    { id: "WKGY94CEkxE", playlist: "WKGY94CEkxE" },
  ];
  const videoUrl = (id, playlist) =>
    `https://www.youtube.com/embed/${id}?loop=1&controls=1&mute=1&playlist=${playlist}`;

  return (
    <>
      <h2
        className="font-bold drop-shadow-md px-4 md:px-8 text-center"
        data-aos="fade-right"
        style={{ fontSize: "clamp(18px, 2vw, 32px)" }}
      >
        Interesting Feature of Chitral Green Guest House
      </h2>
      <div className="flex flex-col  px-4 md:px-8 my-16 lg:flex-row justify-center items-center lg:space-x-4 space-y-4 lg:space-y-0" data-aos="fade-up">
        {videoIds.map((idObj, index) => (
          <div key={index} className="w-full rounded-xl lg:w-1/2">
            <iframe
              width="100%"
              height="335"
              src={videoUrl(idObj.id, idObj.playlist)}
              title={`YouTube video player - ${idObj.playlist}`}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="object-cover rounded-md"
            ></iframe>
          </div>
        ))}
      </div>
    </>
  );
};

export default VideoEmbed;
