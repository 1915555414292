"use client"
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRooms } from '../../redux/actions/roomAction';
import AOS from 'aos';
import 'aos/dist/aos.css';
function Rooms() {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(state => state.rooms);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    dispatch(fetchRooms());
  }, [dispatch]);
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex items-center space-x-2" data-aos="fade-up">
          <div className="w-4 h-4 bg-blue-500 rounded-full animate-ping"></div>
          <div className="w-4 h-4 bg-blue-500 rounded-full animate-ping delay-200"></div>
          <div className="w-4 h-4 bg-blue-500 rounded-full animate-ping delay-400"></div>
        </div>
        <p className="text-gray-500 text-lg ml-4">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen" >
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert" data-aos="fade-up">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-white my-8 px-4 md:px-8'>
      <div>
      <h2
        className="font-bold urbanist drop-shadow-md"
        data-aos="fade-right"
        style={{ fontSize: "clamp(18px, 2vw, 32px)" }}
      >
        Chitral Green Guest House Rooms
      </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-10'>
          {data.map((item, index) => (
            <div key={index} className='flex flex-col gap-3 w-full h-full' >
              <Link
                to={`/room?id=${item.id}`}
                onClick={handleLinkClick}
              >
                <img src={`https://api.chitralgreenguesthouse.com/uploads/${item.room_files[0]}`} alt={item.name} className='max-h-[300px] min-h-[300px] w-full hover:shadow-lg' data-aos="fade-up"/>
              </Link>
              <Link
                to={`/room?id=${item.id}`}
                className='font-bold text-lg text-black hover:text-green-400 hover:underline'
                onClick={handleLinkClick}
                data-aos="fade-up"
              >
                {item.name}
              </Link>
              <div className='flex justify-between' data-aos="fade-up">
                <div className='flex '>
                  <div className=' p-1 text-sm text-gray-500'>PKR - {item.price}</div>
                </div>
                <div className='flex relative'>
                  <Link to={`/room?id=${item.id}`} className='bg-green-500 hover:text-white  hover:border absolute bottom-0 right-0 whitespace-nowrap self-baseline font-semibold px-3 py-1' onClick={handleLinkClick}>
                    BOOK NOW
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Rooms
