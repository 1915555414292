import React,{useEffect} from 'react'
import logo from '../../Assets/logo/logo.png'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll';
import { FiPhone } from "react-icons/fi";
import { FaRegEnvelope } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "aos/dist/aos.css";
import AOS from "aos";
function Footer() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div className='urbanist relative border-b-2 border-t-2 border-[#3b3b3b] bg-[#F7F7F7] min-h-[47vh] max-h-fit w-full text-[#7A8395] px-4 md:px-8'>
        <div className='pt-20 md:grid flex flex-col justify-center mx-auto md:grid-cols-2 lg:grid-cols-4 gap-y-4 text-left '>
          <div >
            <div data-aos="fade-up">
              <Link to="/" >
                <img className="h-16  -translate-x-2" src={logo} alt="Chitral Green Guest House" onClick={handleLinkClick} />
              </Link>
            </div >
            <div className='pt-4 pb-8' data-aos="fade-up">
              <div className='pb-5'><HiOutlineLocationMarker className='text-green-500 inline-block font-bold mr-2 text-[17px]' />Main Chital City Near Chitral Officer Mess</div>
              <div className='pb-5'><a href="mailto:chitralgreenguesthouse@gmail.com" className=' hover:text-green-500 transition-all ease-out duration-150 text-[17px]'><FaRegEnvelope className='text-green-500 inline-block mr-3' /> chitralgreenguesthouse11@gmail.com</a></div>
              <div><a href="tel:+92 345 9388275" className=' hover:text-green-500 transition-all ease-out duration-150 text-[17px]'><FiPhone className='text-green-500 inline-block mr-3' />+92 300 5821648 </a></div>
            </div>
          </div>
          <div className='md:ml-7'>
            <h2 className='pb-8 font-bold text-[20px] text-black' data-aos="fade-up">LINKS</h2>
            <ul className='uppercase' data-aos="fade-up">
              <li className='pb-5'><Link to="/" className='cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}> Home </Link></li>
              <li className='pb-5'><Link to="/rooms" className='cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}> Rooms</Link></li>
              <li className='pb-5'><Link to="/dining" className='cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}> DINING </Link></li>
              <li className='pb-5'><Link to="/reviews" className='pb-5 cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}> Reviews </Link></li>
            </ul>
          </div>
          <div>
            <h2 className='pb-8 font-bold text-[20px] text-black ' data-aos="fade-up">Other Links</h2>
            <ul className='md:mb-20 uppercase' data-aos="fade-up">
              <li className='pb-5 '><Link to="/farm_house" className=' cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}> FARM HOUSE</Link></li>
              <li className='pb-5 '><Link to="/garm_chasma" className=' cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}> GARM CHASMA </Link></li>
              <li className='pb-5 '><Link to="/about" className=' cursor-pointer hover:text-green-500 ease-out' onClick={handleLinkClick}>About</Link></li>
              <li className='pb-5 '><Link to="contact" className=' cursor-pointer hover:text-green-500 ease-out'>Contact</Link></li>
            </ul>
          </div>
          <div>
            <h2 className=' pb-8 capitalize font-bold text-[20px] text-black  ' data-aos="fade-up">LOCATION</h2>
            <div className='mb-3 md:mb-0 ' data-aos="fade-up">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.875407151739!2d71.77785677464601!3d35.85205852057302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38db53038db262e7%3A0x4a79a72ed0e640cf!2sChitral%20Green%20Guest%20House!5e0!3m2!1sen!2s!4v1724090761048!5m2!1sen!2s" title='chitral green guest house' style={{ border: "0" }} width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-col md:flex-row flex text-center md:text-left md:justify-between text-[#7A8395] bg-[#f7f7f7] pt-8 pb-4 px-2 md:px-4'>
        <div >
          <p>&#169;{new Date().getFullYear()} CHITRAL GREEN GUEST HOUSE | All Rights Reserved & Designed by <a href="https://alpharisetech.com" target='_blank' rel='noreferrer' className='hover:text-green-500 transition-all duration-300 ease-in-out hover:underline'>ALPHARISETECH.COM</a></p>
        </div>
        <div>
        </div>
      </div>
    </>
  )
}

export default Footer
