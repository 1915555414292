import React, { useState, useEffect } from "react";
import shatsapplogo from '../../Assets/whats app logo.png';
import { FaArrowUp } from "react-icons/fa";
import { animateScroll as scroll } from 'react-scroll';
import cal from '../../Assets/cal.png';
const WhatsApp = () => {
  const [isVisible, setIsVisible] = useState(false);

  const phoneNumber = "+923005821648"; 
  const message = "Hello, I have a question!"; 

  const generateWhatsAppLink = () => {
    const url = `https://wa.me/${phoneNumber}/?text=${encodeURIComponent(message)}`;
    return url;
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 800,
      smooth: "easeInOutQuad"
    });
  };

  const generateCallLink = () => {
    const url2 = `tel:${phoneNumber}`;
    return url2;
  };
  const toggleVisibility = () => {
    if (window.pageYOffset > 70) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
        <a href={generateCallLink()} target="_blank" rel="noopener noreferrer" className="bg-blue-500 shadow-lg">
        <img src={cal} alt=""
          style={{ width: "50px", height: "50px" }}
          className="ml-4 lg:ml-8 fixed bottom-[5.99rem]  cursor-pointer shadow-lg p-[12px] shadow-black z-[1100] bg-blue-500 rounded-[100%]"
        />
      </a>
      <div>
        <a href={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer" className="bg-green-500 shadow-lg">
          
            <img
              src={shatsapplogo}
              alt="WhatsApp"
              style={{ width: "50px", height: "50px" }}
              className="ml-4 lg:ml-8 fixed bottom-8 cursor-pointer bg-green-500 rounded-[100%] p-[10px] shadow-xl drop-shadow-xl z-[1100]"
            />
         
        </a>
      </div>

      {isVisible && (
        <div 
          onClick={scrollToTop} 
          className="fixed right-4  lg:right-8 bottom-10 bg-green-500 drop-shadow-md text-white flex justify-center items-center rounded-full w-[45px] h-[45px] cursor-pointer shadow-lg z-[1100] hover:bg-green-600"
        >
          <FaArrowUp size={21} />
        </div>
      )}
    </div>
  );
};

export default WhatsApp;
