import React from "react";

const VideoEmbed = () => {
  const videoIds = [
    { id: "9VfNOHdB_eI", playlist: "9VfNOHdB_eI" },
    { id: "K1lc-n43u6Q", playlist: "K1lc-n43u6Q" },
    { id: "QBCne8oT3vg", playlist: "QBCne8oT3vg" },
  ];

  const videoUrl = (id, playlist) =>
    `https://www.youtube.com/embed/${id}?autoplay=1&loop=1&controls=1&mute=1&playlist=${playlist}`;

  return (
    <div className="grid grid-cols-1 gap-4 mt-16 lg:grid-cols-3 md:grid-cols-2">
      {videoIds.map((idObj, index) => (
        <div key={index} className="w-full rounded-xl">
          <iframe
            width="100%"
            height="300"
            src={videoUrl(idObj.id, idObj.playlist)}
            title={`YouTube video player - ${idObj.playlist}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="object-cover rounded-md"
          ></iframe>
        </div>
      ))}
    </div>
  );
};

export default VideoEmbed;
