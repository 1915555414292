import React from 'react'
import ReviewsSection1 from './ReviewsSection1'
import VideoEmbed from './VideoEmbed'
import ReviewsContent from './ReviewsContent'

const Reviews = () => {
  return (
    <div>
      <ReviewsSection1 />
      <VideoEmbed />
      <ReviewsContent />
    </div>
  )
}

export default Reviews