import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function DrivingDirections() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="md:my-12 urbanist py-10 px-4 md:px-8">
      <h2
        className="font-semibold pb-8 max-w-xl mx-auto text-center "
        style={{ fontSize: "clamp(20px, 2vw, 32px)" }}
        data-aos="fade-up"
      >
        Driving Directions From Chitral to  Chitral Green Guest House
      </h2>
      <div className="mx-auto flex flex-col lg:flex-row items-start justify-between">

        <div className="w-full  lg:w-1/2 mt-8 lg:mt-0" data-aos="fade-right">

          <ul
            className="mt-4 space-y-4"
            style={{ fontSize: "clamp(18px, 1.5vw, 24px)" }}
          >
            <li>
              <strong>Head south</strong> on Bypass Rd and pass Pakistan Trading
              Company (350 m).
            </li>
            <li>
              <strong>Turn right</strong> at Ikhlaq IT Services onto Biron Shal
              Rd (250 m).
            </li>
            <li>
              <strong>Sharp right</strong> at Revenue Record Room and pass DHO
              Office (450 m).
            </li>
            <li>
              <strong>Turn right</strong> at Chitral Scouts Mess (130 m).
            </li>
            <li>
              <strong>Turn left</strong> at Chitral Green Guest House; it will
              be on the right (25 m).
            </li>
          </ul>
        </div>

        <div className="w-full lg:w-1/2 lg:pl-8" data-aos="fade-left">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d3233.8844247128195!2d71.78101277568797!3d35.851837420583394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x38db528d20ebd627%3A0xf4bf936aeda4e0f!2sChitr%C4%81l%2C%20Pakistan!3m2!1d35.853994799999995!2d71.7866292!4m5!1s0x38db53038db262e7%3A0x4a79a72ed0e640cf!2sVQ2H%2BJPG%2C%20Chitr%C4%81l%2C%20Chitral%2C%20Khyber%20Pakhtunkhwa%2C%20Pakistan!3m2!1d35.8519781!2d71.78045019999999!5e0!3m2!1sen!2suk!4v1725566886332!5m2!1sen!2suk"
            width="100%"
            height="290"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Chitral Green Guest House Map"
            className="rounded-lg shadow-md"
          ></iframe>
        </div>

      </div>
    </section>
  );
}

export default DrivingDirections;
