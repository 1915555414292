import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { FaParking, FaWifi, FaSwimmingPool, FaShuttleVan, FaUtensils, FaChild, FaBed, FaConciergeBell } from "react-icons/fa";

function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease",
    });
  }, []);

  const amenities = [
    { icon: <FaParking size={24} />, text: "Free parking" },
    { icon: <FaWifi size={24} />, text: "Free WiFi" },
    { icon: <FaSwimmingPool size={24} />, text: "Outdoor swimming pool" },
    { icon: <FaShuttleVan size={24} />, text: "Airport shuttle" },
    { icon: <FaUtensils size={24} />, text: "Breakfast" },
    { icon: <FaChild size={24} />, text: "Family rooms" },
    { icon: <FaBed size={24} />, text: "Non-smoking rooms" },
    { icon: <FaConciergeBell size={24} />, text: "Room service" },
  ];

  return (
    <div className="urbanist px-4 md:px-8 py-16 ">
      <h2 className="text-3xl font-semibold text-center mb-8" data-aos="fade-up">Amenities</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {amenities.map((amenity, index) => (
          <div 
            key={index} 
            className="flex items-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            data-aos="fade-up"
          >
            <div className="mr-4 text-green-500">
              {amenity.icon}
            </div>
            <p className="text-lg text-gray-700">{amenity.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Content;
