import React from 'react'
import Content from './Content'
import Content2 from './Content2'
import AboutSection1 from './AboutSection1'

function About() {
  return (
    <div>
      <AboutSection1 />
      <Content />
      <Content2 />
    </div>
  )
}

export default About