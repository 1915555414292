import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

function Content() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease",
    });
  }, []);

  return (
    <div className="px-4 md:px-8 bg-gray-100 py-8">
      <div className="container mx-auto">
        <div
          className="bg-white p-6 rounded-lg shadow-lg "
          data-aos="fade-up"
        >
          <h2 className="urbanist text-2xl md:text-3xl font-bold text-gray-800 mb-4">
            Welcome to Chitral Green Guest House
          </h2>
          <p className="text-gray-700 text-[1rem] md:text-[1.3rem] leading-relaxed urbanist">
            Chitral Green Guest House was started in year 2021 by Shahzada Muhammad Mansoor Ul Mulk. Our motto is “Quality Service with Reasonable Charges.” Guest satisfaction is our top priority. We offer quality services for our national and international guests at reasonable prices in Chitral City. As a result, we receive a high number of local and international tourists booking with us.
            <br /><br />
            Located in the heart of Chitral City, our guest house provides a remarkable view of Chitral City, surrounded by green chinars, majestic mountains, and a peaceful atmosphere. Nearby, you'll find a beautiful trekking route filled with streams and hilly mountains. One of our standout features is our proximity to the Chitral Gol National Park, just a 10-15 minute walk away. The trek offers adventurous routes and magnificent mountain views, making it a popular attraction for our tourists.
            <br /><br />
            Another great feature is our transport facility for both national and international guests. We provide transportation with an expert driver at a cost much lower than the market rate. Our prices remain consistent for both international and local guests.
            <br /><br />
            Thank you for considering Chitral Green Guest House. We hope to welcome you soon to the land of hospitality.
            <br /><br />
            <p className="font-bold text-2xl md:text-3xl mb-2">Regards,</p>
            The Chitral Green Guest House Team
          </p>
        </div>
      </div>
    </div>
  );
}

export default Content;
