import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import Room from './Pages/Room/Room'
import Dining from './Pages/Dining/Dining'
import Reviews from './Pages/reviews/Reviews';
import FarmHouse from './Pages/Farm_House/Farm_House';
import GarmChasma from './Pages/Garm_Chasma/Garm_Chasma';
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import RoomDescription from './Pages/RoomDescription/RoomDescription';
import WhatsApp from './Components/WhatsAppLogo/Whatsapp';

function App() {
  return (
    <>
    <div className='mainbodylayout'>
      <Navbar/>
      <WhatsApp />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/rooms" element={<Room />} />
        <Route exact path="/dining" element={<Dining />} />
        <Route exact path="/reviews" element={<Reviews />} />
        <Route exact path="/farm_house" element={<FarmHouse />} />
        <Route exact path="/garm_chasma" element={<GarmChasma />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/room" element={<RoomDescription />} />
      </Routes>
      <Footer/>
    </div>
    </>
  );
}
export default App;

