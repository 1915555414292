import React, { useState,useEffect } from "react";
import axios from "axios";
import { FaEnvelope, FaUser, FaPhoneAlt, FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Form2 = ({ room_id,room_name }) => {
  const [message, setMessage] = useState("");
  const [bookedDates, setBookedDates] = useState([]);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    startDate: null,
    endDate: null,
    room_name:room_name,
  });
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  useEffect(() => {
    const fetchBookedDates = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_API_ENDPOINT}api/get_room_bookings/${room_id}`
        );
        const dates = response.data.rooms.map((room) => new Date(room.date));
        setBookedDates(dates);
      } catch (err) {
        console.error("Failed to fetch booked dates", err);
      }
    };

    fetchBookedDates();
  }, [room_id]);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleStartDateChange = (date) => {
    setData({ ...data, startDate: date });
  };

  const handleEndDateChange = (date) => {
    setData({ ...data, endDate: date });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      setMessage("Booking order...");
      setError("");
      const url = `${process.env.REACT_APP_NODE_API_ENDPOINT}api/book_room`;
      const bookingData = { ...data, room_id };
      const { data: res } = await axios.post(url, bookingData);
      console.log(res.message);
      setMessage("Your room has been booked successfully. Please wait for a confirmation call.");
      setData({
        name: "",
        email: "",
        phone: "",
        startDate: null,
        endDate: null,
        room_name:room_name,
      });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  const isDateBooked = (date) => {
    return bookedDates.some(
      (bookedDate) => bookedDate.toDateString() === date.toDateString()
    );
  };
  

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1": bookedDates,
    },
  ];

  const CustomInput = ({ value, onClick, placeholder }) => (
    <div className="relative flex items-center w-full">
      <FaCalendarAlt className="text-gray-500 text-lg absolute left-4 mb-4" />
      <input
        type="text"
        className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md mb-4 bg-gray-100 placeholder:text-gray-500 outline-none cursor-pointer"
        onClick={onClick}
        value={value}
        readOnly
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <div className="flex justify-center bg-white p-4 sm:p-6 lg:p-10 rounded-lg shadow-2xl" data-aos="fade-left">
      <form className="flex flex-col w-full max-w-lg" onSubmit={handlesubmit}>
        <h2 className="text-4xl font-bold mb-4">BOOK NOW</h2>
        <div className="flex flex-col space-y-4">
          <div className="relative flex items-center">
            <FaUser className="text-gray-500 text-lg absolute left-4 top-[18px]" />
            <input
              className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md bg-gray-100 placeholder:text-gray-500 outline-none"
              type="text"
              id="name"
              name="name"
              value={data.name}
              onChange={handleChange}
              required
              placeholder="Full Name"
            />
          </div>
          <div className="relative flex items-center">
            <FaEnvelope className="text-gray-500 text-lg absolute left-4 top-[18px]" />
            <input
              className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md bg-gray-100 placeholder:text-gray-500 outline-none"
              type="email"
              id="email"
              name="email"
              value={data.email}
              onChange={handleChange}
              required
              placeholder="Email Address*"
            />
          </div>
          <div className="relative flex items-center">
            <FaPhoneAlt className="text-gray-500 text-lg absolute left-4 top-[18px]" />
            <input
              className="w-full px-6 py-4 border focus:border-green-500 pl-12 rounded-md bg-gray-100 placeholder:text-gray-500 outline-none"
              type="tel"
              id="phone"
              name="phone"
              value={data.phone}
              onChange={handleChange}
              required
              placeholder="Phone"
            />
          </div>
          <div className="md:flex md:flex-row hidden">
            <div className="relative w-full">
              Start Date
            </div>
            <div className="relative w-full md:pl-4">
              End Date
            </div>
          </div>
          <div className="md:hidden">
            Start Date
          </div>
          <div className="flex flex-col md:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full">
              <DatePicker
                selected={data.startDate}
                onChange={handleStartDateChange}
                customInput={<CustomInput placeholder="Select Start Date" />}
                highlightDates={highlightWithRanges}
                filterDate={(date) => !isDateBooked(date)}
                minDate={new Date()}
                popperPlacement="bottom"
                showPopperArrow
              />
            </div>
            <div className="md:hidden">
              End Date
            </div>
            <div className="relative w-full">
              <DatePicker
                selected={data.endDate}
                onChange={handleEndDateChange}
                customInput={<CustomInput placeholder="Select End Date" />}
                highlightDates={highlightWithRanges}
                filterDate={(date) =>
                  !isDateBooked(date) &&
                  (!data.startDate || date > data.startDate)
                }
                minDate={data.startDate || new Date()}
                popperPlacement="bottom"
                showPopperArrow
              />
            </div>
          </div>
          {error && <div className="text-red-500">{error}</div>}
          {message && <div className="text-green-500">{message}</div>}
          <button
            className="bg-green-500 transition-all duration-300 ease-in-out hover:bg-green-600 text-white text-lg font-bold py-4 rounded-lg w-full"
            type="submit"
          >
            BOOK NOW
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form2;
